import React, { useEffect } from 'react'
import "./App.css";
import Main from './Pages/Main';
import RegisCollegeFrame from './Pages/RegisCollegeFrame';
import RegisCollegeFrameVertical from './Pages/RegisCollegeFrameVertical';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


// import { CometChat } from "@cometchat-pro/chat";
// import { CometChatUI } from "./CometChatWorkspace/src";
// const appID = "241639cc23984652";
// const region = "us";
// const authKey = "aeab943b9ec8c4b15c332e5deb0feee5b136e29a";
// const appSetting = new CometChat.AppSettingsBuilder()
//   .subscribePresenceForAllUsers()
//   .setRegion(region)
//   .build();
// CometChat.init(appID, appSetting).then(
//   () => {
//     console.log("Initialization completed successfully");
//     // You can now call login function.
//   },
//   (error) => {
//     console.log("Initialization failed with error:", error);
//     // Check the reason for error and take appropriate action.
//   }
// );

// const uid = "00000univa56";

// CometChat.login(uid, authKey).then(
//   (user) => {
//     console.log("Login Successful:", { user });
//   },
//   (error) => {
//     console.log("Login failed with exception:", { error });
//   }
// );
const regis = true;
function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4CQ9454DGY';
    script.async = true;

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'G-4CQ9454DGY', {
        'cookie_flags': 'samesite=none;secure'
      });
    };

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);


  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'ity5eeod0n');
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<RegisCollegeFrame />} />
          <Route path="/ambassadors" element={<RegisCollegeFrameVertical />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
