import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EmilyAho from "../assests/images/EmilyAho.jpeg";
import NoyGeffen1 from "../assests/images/NoyGeffen1.jpeg";
import SofiaColon from "../assests/images/SofiaColon.jpeg";
import KaylaBusby1 from "../assests/images/KaylaBusby1.jpeg";
import TruleagueLogo from "../assests/icon/TruLea.svg";
import MichelleWang from "../assests/images/MichelleWang.jpeg";
import AshleyPaszko1 from "../assests/images/AshleyPaszko1.jpeg";
import RegisCardVertical from "../Components/RegisCardVertical";
import KristinBarret from "../assests/images/KristinBarret.jpeg";
import IsabellaClarkAlderman from "../assests/images/IsabellaClarkAlderman.jpeg";

import "../assests/css/regisFrameVertical.css";

const cardToShow = 3
const cardArray = [
  {
    id: 1,
    person_name: "Noy Geffen",
    country_name: "Wayland, Massachusetts",
    Major: "Elementary Education and Humanities",
    favourite_spot: "Dance studio (Fine Arts Center)",
    language: "Hebrew, English",
    image_link: NoyGeffen1,
    chat_id : 485
  },
  {
    id: 2,
    person_name: "Sofia Colon",
    country_name: "San juan, puerto rico ",
    Major: "biology, pre med, minor - neuroscience",
    favourite_spot: "Outdoor volleyball lawn",
    language: "spanish, english",
    image_link: SofiaColon,
    chat_id : 488

  },
  {
    id: 3,
    person_name: "Kayla Busby",
    country_name: "Thetford, VT",
    Major: "Nuclear Medicine",
    favourite_spot: "Quad",
    language: "English",
    image_link: KaylaBusby1,
    chat_id : 487

  },
  {
    id: 4,
    person_name: "Emily Aho",
    country_name: "Pelham, NH",
    language: "English",
    Major: "Nursing",
    favourite_spot: "Morrison House front lawn",
    image_link: EmilyAho,
    chat_id : 491

  },
  {
    id: 5,
    person_name: "Kristin Barrett",
    country_name: "Plymouth, MA",
    Major: "Marketing and Communication",
    favourite_spot: "2nd floor of library & the team weight room",
    language: "English",
    image_link: KristinBarret,
    chat_id : 490

  },
  {
    id: 6,
    person_name: "Isabella Clark-Alderman",
    country_name: "Boxford, MA",
    Major: "Nursing",
    favourite_spot: "Grotto",
    language: "English",
    image_link: IsabellaClarkAlderman,
    chat_id : 489

  },
  {
    id: 7,
    person_name: "Michelle Wang",
    country_name: "Stoneham, MA",
    Major: "Nursing",
    favourite_spot: "The library!",
    language: "Chinese, English, Japanese",
    image_link: MichelleWang,
    chat_id : 519

  },
  {
    id: 8,
    person_name: "Ashley Paszko",
    country_name: "Haverhill, MA",
    Major: "Nursing",
    favourite_spot: "The quad hammocks",
    language: "English",
    image_link: AshleyPaszko1,
    chat_id : 518

  },
];

export default function RegisCollegeFrameHorizontal() {
  const [cardIndex, setCardIndex] = useState(0);
  const maxIndex = cardArray.length - 3;
  const cardWidth = 254;

  const scrollLeft = () => {
    let element = document.getElementById("rg-card-container");
    let scrollL = element.scrollLeft;
    let j = parseInt(scrollL / cardWidth);
    if (scrollL > cardWidth * 3) {
      scrollTo(element, j * cardWidth - cardWidth * 3, 0.5);
    } else {
      scrollTo(element, 0, 0.5);
    }
  };

  const scrollRight = () => {
    let element = document.getElementById("rg-card-container");
    let scrollL = element.scrollLeft;
    let j = parseInt(Math.ceil(scrollL / cardWidth));

    scrollTo(element, ((cardWidth * (3 + j) + 20)), 0.5);
  };

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    const length = cardArray.length;
    const i = parseInt(Math.floor(scrollLeft) / cardWidth);
    const newIndex = parseInt(Math.ceil(i > (length - cardToShow) ? (length - cardToShow + 1) : i));

    setCardIndex(newIndex);
  };

  function scrollTo(element, to, duration, onDone) {
    var start = element.scrollLeft,
      change = to - start,
      startTime = performance.now(),
      val,
      now,
      elapsed,
      t;

    function animateScroll() {
      now = performance.now();
      elapsed = (now - startTime) / 1000;
      t = elapsed / duration;

      element.scrollLeft = start + change * easeInOutQuad(t);

      if (t < 1) window.requestAnimationFrame(animateScroll);
      else onDone && onDone();
    }

    animateScroll();
  }

  useEffect(() => {
    const onLoadHandler = function () {
      window.parent.postMessage('iframeLoaded', '*');
      setTimeout(() => {
        let ele = document.querySelector('#root')
        window.parent.postMessage(ele.clientHeight, '*');
      }, 2000)
      console.log("loaded");
    };

    window.addEventListener('load', onLoadHandler);

    return () => {
      window.removeEventListener('load', onLoadHandler);
    };
  }, []);


  function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }

  return (
    <Box>
      <Grid className="v-rg-container" id="rg-mainContainer">
        <Grid className="v-rg-heading">
          Connect with our Current Students
        </Grid>
        <Grid className="v-rg-sub-heading">
          Explore the vibrant world of Regis College firsthand through conversations with our students. From program
          insights to dorm life and exciting clubs, our community is eager to
          share their experiences with you!
        </Grid>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="v-rg-card-container"
            onScroll={handleScroll}
            id="rg-card-container"
          >
            {cardArray.map((e, idx) => {
              return (
                <div className="v-rg-card-wrapper" key={idx}>
                  <RegisCardVertical cardData={e} key={idx} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="v-footer-container">
          <div className="v-footer">Powered By</div>
          <div>
            <a href="https://truleague.com" target="_blank">
              <img
                src={TruleagueLogo}
                height="13px"
                width="25px"
                style={{ padding: "0px 3px 0 6px" }}
              />
            </a>
          </div>
          <a href="https://truleague.com" target="_blank" className="v-text-logo">TruLeague</a>
        </div>
      </Grid>
    </Box>
  );
}
