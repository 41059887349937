import React from "react";

import AddressIcon from "../assests/icon/address-icon.png";
import BusinessIcon from "../assests/icon/business-icon.png";
import LanguageIcon from "../assests/icon/Language.png";

export default function RegisCardVertical({ cardData }) {

  const gtagClick = () => {
    try {
      console.log(window);
      if (window.gtag) {
        window.gtag('event', 'Chat_Ambassador_name_Redirect', {
          event_category: 'Form_Interactions',
          event_label: 'When a user clicks on Chat with Ambassador_name button on the website(Iframe).'
        });
      } else {
        console.log('gtag is not available');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const firstName = cardData.person_name.split(' ')[0];
  const chatId = cardData.chat_id

  return (
    <div className="v-rg-card">
      <div className="v-rg-card-top">
        <img className="v-rg-card-image" src={cardData.image_link} />
      </div>
      <div className="v-rg-card-middle">
        <div className="v-rg-card-name">{cardData.person_name}</div>
        <div className="v-rg-properties-wrapper">
          <div className="v-rg-person-properties v-rg-major">
            <div>
              <img src={BusinessIcon} width={10} height={12} />
            </div>
            <div className="v-rg-property-text">{cardData.Major}</div>
          </div>
          <div className="v-rg-person-properties">
            <div>
              <img src={AddressIcon} width={10} height={14} />
            </div>
            <div className="v-rg-property-text">{cardData.country_name}</div>
          </div>
          <div className="v-rg-person-properties">
            <div>
              <img src={LanguageIcon} width={10} height={14} />
            </div>
            <div className="v-rg-property-text">{cardData.language}</div>
          </div>
        </div>
        <div className="v-rg-card-btn-wrapper" onClick={gtagClick()}>
          <a href={`https://regis-college.truleague.com/conversations/${chatId}`} target='_blank'>
            <div className="v-rg-btn-text-wrapper">
              <div className="v-rg-btn-text">Chat with {firstName}</div>
              {/* <div className="v-rg-btn-icon-wrapper">
                <div className="v-rg-btn-line"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                  <path d="M10.109 21.3379C10.0576 21.2866 10.0168 21.2257 9.98901 21.1586C9.9612 21.0915 9.94689 21.0196 9.94689 20.947C9.94689 20.8743 9.9612 20.8024 9.98901 20.7353C10.0168 20.6682 10.0576 20.6073 10.109 20.556L19.1124 11.5525L1.18448 11.5525C1.03791 11.5525 0.897354 11.4943 0.793718 11.3907C0.690084 11.2871 0.631864 11.1465 0.631864 10.9999C0.631864 10.8534 0.690084 10.7128 0.793718 10.6092C0.897354 10.5055 1.03791 10.4473 1.18448 10.4473L19.1124 10.4473L10.109 1.44389C10.0576 1.39255 10.0169 1.3316 9.98911 1.26451C9.96132 1.19743 9.94702 1.12553 9.94702 1.05292C9.94702 0.980308 9.96132 0.908409 9.98911 0.841326C10.0169 0.774242 10.0576 0.713289 10.109 0.661946C10.1603 0.610602 10.2213 0.569875 10.2883 0.542088C10.3554 0.514301 10.4273 0.5 10.4999 0.5C10.5725 0.5 10.6444 0.514301 10.7115 0.542088C10.7786 0.569875 10.8396 0.610602 10.8909 0.661946L20.8379 10.609C20.8893 10.6603 20.9301 10.7212 20.9579 10.7883C20.9857 10.8554 21 10.9273 21 10.9999C21 11.0726 20.9857 11.1445 20.9579 11.2116C20.9301 11.2786 20.8893 11.3396 20.8379 11.3909L10.8909 21.3379C10.8396 21.3893 10.7786 21.4301 10.7116 21.4579C10.6445 21.4857 10.5726 21.5 10.4999 21.5C10.4273 21.5 10.3554 21.4857 10.2883 21.4579C10.2212 21.4301 10.1603 21.3893 10.109 21.3379Z" fill="#A52238" />
                </svg>
              </div> */}
            </div>
          </a>
        </div>
      </div>
      <div className="v-rg-card-bottom">
        <div className="v-rg-card-end-text-wrapper">
          <div className="v-rg-text-1">Favorite spot on campus</div>
          <div className="v-rg-text-2">{cardData.favourite_spot}</div>
        </div>
      </div>
    </div>
  );
}
