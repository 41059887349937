import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RegisCard from "../Components/RegisCard";
import EmilyAho from "../assests/images/EmilyAho.jpeg";
import NoyGeffen1 from "../assests/images/NoyGeffen1.jpeg";
import SofiaColon from "../assests/images/SofiaColon.jpeg";
import KaylaBusby1 from "../assests/images/KaylaBusby1.jpeg";
import TruleagueLogo from "../assests/icon/TruLea.svg";
import MichelleWang from "../assests/images/MichelleWang.jpeg";
import AshleyPaszko1 from "../assests/images/AshleyPaszko1.jpeg";
import KristinBarret from "../assests/images/KristinBarret.jpeg";
import IsabellaClarkAlderman from "../assests/images/IsabellaClarkAlderman.jpeg";

import "../assests/css/regisFrame.css";

const cardToShow = 3
const cardArray = [
  {
    id: 1,
    person_name: "Noy Geffen",
    country_name: "Wayland, Massachusetts",
    Major: "Elementary Education and Humanities",
    favourite_spot: "Dance studio (Fine Arts Center)",
    language: "Hebrew, English",
    image_link: NoyGeffen1,
    chat_id : 485
  },
  {
    id: 2,
    person_name: "Sofia Colon",
    country_name: "San juan, puerto rico ",
    Major: "biology, pre med, minor - neuroscience",
    favourite_spot: "Outdoor volleyball lawn",
    language: "spanish, english",
    image_link: SofiaColon,
    chat_id : 488

  },
  {
    id: 3,
    person_name: "Kayla Busby",
    country_name: "Thetford, VT",
    Major: "Nuclear Medicine",
    favourite_spot: "Quad",
    language: "English",
    image_link: KaylaBusby1,
    chat_id : 487

  },
  {
    id: 4,
    person_name: "Emily Aho",
    country_name: "Pelham, NH",
    language: "English",
    Major: "Nursing",
    favourite_spot: "Morrison House front lawn",
    image_link: EmilyAho,
    chat_id : 491

  },
  {
    id: 5,
    person_name: "Kristin Barrett",
    country_name: "Plymouth, MA",
    Major: "Marketing and Communication",
    favourite_spot: "2nd floor of library & the team weight room",
    language: "English",
    image_link: KristinBarret,
    chat_id : 490

  },
  {
    id: 6,
    person_name: "Isabella Clark-Alderman",
    country_name: "Boxford, MA",
    Major: "Nursing",
    favourite_spot: "Grotto",
    language: "English",
    image_link: IsabellaClarkAlderman,
    chat_id : 489

  },
  {
    id: 7,
    person_name: "Michelle Wang",
    country_name: "Stoneham, MA",
    Major: "Nursing",
    favourite_spot: "The library!",
    language: "Chinese, English, Japanese",
    image_link: MichelleWang,
    chat_id : 519

  },
  {
    id: 8,
    person_name: "Ashley Paszko",
    country_name: "Haverhill, MA",
    Major: "Nursing",
    favourite_spot: "The quad hammocks",
    language: "English",
    image_link: AshleyPaszko1,
    chat_id : 518

  },
];

export default function RegisCollegeFrame() {
  const [cardIndex, setCardIndex] = useState(0);
  const maxIndex = cardArray.length - 3;
  const cardWidth = 237;

  const scrollLeft = () => {
    let element = document.getElementById("rg-card-container");
    let scrollL = element.scrollLeft;
    let j = parseInt(scrollL / cardWidth);

    if (scrollL > cardWidth * 3) {
      scrollTo(element, j * cardWidth - cardWidth * 3, 0.75);
    } else {
      scrollTo(element, 0, 0.75);
    }
  };

  const scrollRight = () => {
    let element = document.getElementById("rg-card-container");
    let scrollL = element.scrollLeft;
    let j = parseInt(Math.ceil(scrollL / cardWidth));

    scrollTo(element, ((cardWidth * (3 + j))), 0.75);
  };

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    const length = cardArray.length;
    const i = (Math.ceil(scrollLeft) / cardWidth);
    const newIndex = (Math.ceil(i > (length - cardToShow) ? (length - cardToShow + 1) : i));

    setCardIndex(newIndex);
  };

  function scrollTo(element, to, duration, onDone) {
    var start = element.scrollLeft,
      change = to - start,
      startTime = performance.now(),
      val,
      now,
      elapsed,
      t;

    function animateScroll() {
      now = performance.now();
      elapsed = (now - startTime) / 1000;
      t = elapsed / duration;

      element.scrollLeft = start + change * easeInOutQuad(t);

      if (t < 1) window.requestAnimationFrame(animateScroll);
      else onDone && onDone();
    }

    animateScroll();
  }

  function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }

  return (
    <Box>
      <Grid className="rg-container">
        <Grid className="rg-heading">
          Connect with our Current Students
        </Grid>
        <Grid className="rg-sub-heading">
          Explore the vibrant world of Regis College firsthand through conversations with our students. From program
          insights to dorm life and exciting clubs, our community is eager to
          share their experiences with you!
        </Grid>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className={`scroll-left ${cardIndex === 0 ? "hide" : ""}`}
            onClick={scrollLeft}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M11.707 0.662075C11.7583 0.713398 11.7991 0.774343 11.8269 0.841428C11.8547 0.908515 11.869 0.980424 11.869 1.05305C11.869 1.12567 11.8547 1.19758 11.8269 1.26467C11.7991 1.33175 11.7583 1.3927 11.707 1.44402L2.70352 10.4475L20.6314 10.4475C20.778 10.4475 20.9186 10.5057 21.0222 10.6093C21.1258 10.7129 21.1841 10.8535 21.1841 11.0001C21.1841 11.1466 21.1258 11.2872 21.0222 11.3908C20.9186 11.4945 20.778 11.5527 20.6314 11.5527L2.70352 11.5527L11.707 20.5561C11.7583 20.6075 11.799 20.6684 11.8268 20.7355C11.8546 20.8026 11.8689 20.8745 11.8689 20.9471C11.8689 21.0197 11.8546 21.0916 11.8268 21.1587C11.799 21.2258 11.7583 21.2867 11.707 21.3381C11.6556 21.3894 11.5947 21.4301 11.5276 21.4579C11.4605 21.4857 11.3886 21.5 11.316 21.5C11.2434 21.5 11.1715 21.4857 11.1044 21.4579C11.0373 21.4301 10.9764 21.3894 10.925 21.3381L0.977993 11.391C0.926613 11.3397 0.885853 11.2788 0.858043 11.2117C0.830234 11.1446 0.815919 11.0727 0.815919 11.0001C0.815919 10.9274 0.830234 10.8555 0.858043 10.7884C0.885853 10.7214 0.926613 10.6604 0.977993 10.6091L10.925 0.662074C10.9763 0.610694 11.0373 0.569932 11.1044 0.542123C11.1715 0.514314 11.2434 0.500001 11.316 0.500001C11.3886 0.500001 11.4605 0.514314 11.5276 0.542123C11.5947 0.569932 11.6556 0.610694 11.707 0.662075Z"
                fill="#A52238"
              />
            </svg>
          </button>
          <div
            className="rg-card-container"
            onScroll={handleScroll}
            id="rg-card-container"
          >
            {cardArray.map((e, idx) => {
              return (
                <div className="rg-card-wrapper" key={idx}>
                  <RegisCard cardData={e} key={idx} />
                </div>
              );
            })}
          </div>
          <button
            className={`scroll-right ${cardIndex == maxIndex ? "hide" : ""}`}
            onClick={scrollRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
            >
              <path
                d="M10.109 21.3379C10.0576 21.2866 10.0168 21.2257 9.98901 21.1586C9.9612 21.0915 9.94689 21.0196 9.94689 20.947C9.94689 20.8743 9.9612 20.8024 9.98901 20.7353C10.0168 20.6682 10.0576 20.6073 10.109 20.556L19.1124 11.5525L1.18448 11.5525C1.03791 11.5525 0.897354 11.4943 0.793718 11.3907C0.690084 11.2871 0.631864 11.1465 0.631864 10.9999C0.631864 10.8534 0.690084 10.7128 0.793718 10.6092C0.897354 10.5055 1.03791 10.4473 1.18448 10.4473L19.1124 10.4473L10.109 1.44389C10.0576 1.39255 10.0169 1.3316 9.98911 1.26451C9.96132 1.19743 9.94702 1.12553 9.94702 1.05292C9.94702 0.980308 9.96132 0.908409 9.98911 0.841326C10.0169 0.774242 10.0576 0.713289 10.109 0.661946C10.1603 0.610602 10.2213 0.569875 10.2883 0.542088C10.3554 0.514301 10.4273 0.5 10.4999 0.5C10.5725 0.5 10.6444 0.514301 10.7115 0.542088C10.7786 0.569875 10.8396 0.610602 10.8909 0.661946L20.8379 10.609C20.8893 10.6603 20.9301 10.7212 20.9579 10.7883C20.9857 10.8554 21 10.9273 21 10.9999C21 11.0726 20.9857 11.1445 20.9579 11.2116C20.9301 11.2786 20.8893 11.3396 20.8379 11.3909L10.8909 21.3379C10.8396 21.3893 10.7786 21.4301 10.7116 21.4579C10.6445 21.4857 10.5726 21.5 10.4999 21.5C10.4273 21.5 10.3554 21.4857 10.2883 21.4579C10.2212 21.4301 10.1603 21.3893 10.109 21.3379Z"
                fill="#A52238"
              />
            </svg>
          </button>
        </div>
        <div className="footer-container">
          <div className="footer">Powered By</div>
          <div>
            <a href="https://truleague.com" target="_blank">
              <img
                src={TruleagueLogo}
                height='13px'
                width='25px'
                style={{ padding: "0px 3px 0px 6px" }}
              />
            </a>
          </div>
          <a href="https://truleague.com" target="_blank" className="v-text-logo">TruLeague</a>
        </div>
      </Grid>
    </Box>
  );
}
